import React from 'react';
import './CityDetails.css';
import { getCloudinaryImagePath } from '../App/App';

// text
const sectionAParagraphTextA =
      'Join an NFT permissioned sandbox metropolis.. a skysteading haven where we explore new economic modalities,'
    + ' CloudCitizenDAO and art exhibitions.'
const sectionAParagraphTextB =
      'What will you do with your zone?'

const sectionBParagraphTextA =
      'Display, auction and advertise in the bustling downtown market, run on a ReSource based economy'

const sectionA =
    { titleText : '#CityOnTheCloud'
    , paragraphText : [sectionAParagraphTextA, sectionAParagraphTextB]
    , jpgImgSrcName : 'edge_ndxqbk.jpg'
    , webpImgSrcName : 'edge_ze7hqu.webp'
    }

const sectionB =
    { titleText : 'The Cloud Bazaar'
    , paragraphText : [sectionBParagraphTextA]
    , jpgImgSrcName : 'bazaar_wz5a2m.jpg'
    , webpImgSrcName : 'bazaar_pmpwew.webp'
    }

const cityDetailsSections =
    [ sectionA
    , sectionB
    ]

class CityDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: null
        };
    }


    // render
    renderCityDetailsSections() {
        return cityDetailsSections.map( ( details ) =>
            <div
                className={'city-details-group'}
            >
                <div
                    className={'city-details-text-group'}
                >
                    <h2>
                        { details.titleText
                        }
                    </h2>
                    { details.paragraphText.map( (text) =>
                        <p>
                            { text
                            }
                        </p>
                    ) }
                </div>

                <picture id={'cloud'}>
                    <source
                        srcSet={getCloudinaryImagePath(details.webpImgSrcName)}
                        type={'image/webp'}
                    />
                    <source
                        srcSet={getCloudinaryImagePath(details.jpgImgSrcName)}
                        type={'image/png'}
                    />
                    < img
                        src={getCloudinaryImagePath(details.jpgImgSrcName)}
                        alt={'resource cloud city nft preview'}
                    />
                </picture>

            </div>
        )
    }

    render() {
        return (
            <section
                id={'city-details'}
            >
                { this.renderCityDetailsSections()
                }
            </section>
        )
    }
}

export default CityDetails;