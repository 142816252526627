import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter, faDiscord } from "@fortawesome/free-brands-svg-icons"
import "./Nav.css"
import { getCloudinaryImagePath } from "../App/App"

//images
const logoSrcName = "resource-logotype-white_gs8qhg.svg"

// data
const twitter = {
  href: "https://twitter.com/resourcemutual",
  buttonText: "twitter",
  icon: faTwitter,
}
const discord = { href: "https://discord.gg/tbFcUmru8K", buttonText: "discord", icon: faDiscord }

const navLinks = [discord, twitter]

class Nav extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: null,
    }

    this.handleClickNavLink = this.handleClickNavLink.bind(this)
    this.handleClickLogo = this.handleClickLogo.bind(this)
    this.renderLinks = this.renderLinks.bind(this)
  }

  // handle event
  handleClickNavLink(event) {
    this.setState({ selected: event.target.id })
  }

  handleClickLogo(event) {
    event.preventDefault()
    this.setState({ selected: null })
  }

  // render
  renderLinks() {
    return navLinks.map((navLink) => (
      <a
        href={navLink.href}
        target={"_blank"}
        rel={"noreferrer"}
        id={navLink.buttonText}
        key={navLink.buttonText}
      >
        <FontAwesomeIcon icon={navLink.icon} />
      </a>
    ))
  }

  renderLogo() {
    return (
      <img src={getCloudinaryImagePath(logoSrcName)} alt={"resource finance logo"} id={"logo"} />
    )
  }

  render() {
    return (
      <nav id={"nav"}>
        {this.renderLogo()}
        <div id={"nav-link-list"}>{this.renderLinks()}</div>
      </nav>
    )
  }
}

export default Nav
