import "./Form.css"
import React from "react"
import axios from "axios"
import { customAlphabet } from "nanoid"
import { useState } from "react"

const nanoid = customAlphabet("1234567890abcdef", 10)
const apiKey = process.env.REACT_APP_CIO_API_KEY
const siteId = process.env.REACT_APP_CIO_SITE_ID

export const Form = () => {
  const [form, setForm] = useState(true)
  const [email, setEmail] = useState()
  const [handle, setHandle] = useState()
  const [submitted, setSubmitted] = useState(false)

  const handleChange = (e) => {
    const { value, name } = e.target
    if (name === "email") setEmail(value.trim().toLowerCase())
    if (name === "handle") setHandle(value.trim().toLowerCase())
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (email && email !== "") {
      try {
        await submitEmailToCustomerio(email, handle)
        setSubmitted(true)
        setEmail("")
      } catch (e) {
        console.log("Error capturing email: ", e)
      }
    } else {
      return
    }
  }

  const handleClose = (e) => {
    setForm(false)
  }

  return (
    <section id={"form"} className={form ? "" : "hide"}>
      <div id={"futureIsMutualContentContainer"}>
        <div id={"form-header"}>
          <h4> {"Get updates"}</h4>
          <a onClick={handleClose}>X</a>
        </div>

        <form onSubmit={handleSubmit} onChange={handleChange}>
          <input id={"email"} name="email" type={"email"} placeholder={"Email"} required />
          <input id={"telegram"} name="handle" type={"text"} placeholder={"Telegram handle"} />
          <input id={"submit"} type={"submit"} value={submitted ? "Submitted!" : "Take part"} />
        </form>
      </div>
    </section>
  )
}

const submitEmailToCustomerio = async (email, handle) => {
  const customerIOConfig = {
    method: "POST",
    url: "https://track.customer.io/api/v1/forms/cloud_city_email/submit",
    crossDomain: true,
    mode: "CORS",
    headers: {
      "Content-Type": "text/plain",
    },
    auth: {
      username: siteId,
      password: apiKey,
    },
    data: JSON.stringify({
      data: {
        email: email,
        handle: handle,
        id: nanoid(),
      },
    }),
  }

  try {
    return await Promise.all([axios(customerIOConfig)])
  } catch (e) {
    console.log("Error submitting to segment & customerio: ", e)
  }
}

export default Form
