import React from 'react';
import './Map.css';
import { getCloudinaryImagePath } from '../App/App';

// text

const map =
    { webpImgSrcName : 'map_kvc0w6.webp'
    , jpgImgSrcName : 'map_ibpiws.jpg'
    }

class Map extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: null
        };
    }


    // render
    renderMap() {
        return (
            <picture id={'cloud'}>
                <source
                    srcSet={getCloudinaryImagePath(map.webpImgSrcName)}
                    type={'image/webp'}
                />
                <source
                    srcSet={getCloudinaryImagePath(map.jpgImgSrcName)}
                    type={'image/png'}
                />
                < img
                    src={getCloudinaryImagePath(map.jpgImgSrcName)}
                    alt={'resource cloud city nft preview'}
                />
            </picture>
        )
    }

    render() {
        return (
            <section
                id={'map'}
            >
                { this.renderMap()
                }
            </section>
        )
    }
}

export default Map;