import React from 'react';
import './Hero.css';
import { getCloudinaryImagePath } from '../App/App';

//images
const cloudPngSrcName = 'cloud_jywfnd.png'
const cloudWebpSrcName = 'cloud_rgfvqk.webp'

// text
const heroTitleText = 'Cloud City'
const paragraphTextA = 'A metaverse co-creation between multiple artists, thinkers, and governance tinkerers.'
const paragraphTextB = 'The upcoming sale will enable you to buy blocks of NFT:City - and create it together with us!'


class Hero extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: null
        };
    }


    // render
    renderText() {
        return(
            <header
                id={'hero-text'}
            >
                <h1>{heroTitleText}</h1>
                <p>{paragraphTextA}</p>
                <p>{paragraphTextB}</p>
                <picture id={'cloud'}>
                    <source
                        srcSet={getCloudinaryImagePath(cloudWebpSrcName)}
                        type={'image/webp'}
                    />
                    <source
                        srcSet={getCloudinaryImagePath(cloudPngSrcName)}
                        type={'image/png'}
                    />
                    <img
                        src={getCloudinaryImagePath(cloudPngSrcName)}
                        alt={'floating cloud'}
                    />
                </picture>

            </header>
        )
    }

    render() {
        return (
            <section
                id={'hero'}
            >
                { this.renderText()
                }
            </section>
        )
    }
}

export default Hero;