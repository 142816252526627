import React from "react";
import './App.css';

import Nav from '../Nav/Nav';
import Footer from '../Footer/Footer';
import Hero from '../Hero/Hero';
import CityDetails from '../CityDetails/CityDetails';
import Map from '../Map/Map'
import Form from '../Form/Form'

export const getCloudinaryImagePath = (fileName) => 'https://res.cloudinary.com/resource-network/image/upload/v1633637911/resource-cloud-city/' + fileName;

function App() {
    return (
        <main className="App">
            <Nav />
            <Form/>
            <Hero />
            <CityDetails />
            <Map />
            <Footer />
        </main>
    );
}

export default App;
